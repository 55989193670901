@charset "UTF-8";
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

a {
  text-decoration: none;
}

ul {
  margin: 0;
  padding: 0;
}

li {
  list-style-type: none;
}

@font-face {
  font-family: Afrah;
  src: url(../assets/CustomizerFont/Afrah-bold.ttf);
}
@font-face {
  font-family: Alexbrush;
  src: url(../assets/CustomizerFont/Alexbrush-regular.ttf);
}
@font-face {
  font-family: Amazone;
  src: url(../assets/CustomizerFont/Amazone.ttf);
}
@font-face {
  font-family: Ananda;
  src: url(../assets/CustomizerFont/Ananda-personal-use.ttf);
}
@font-face {
  font-family: Aristotelicasmallcaps;
  src: url(../assets/CustomizerFont/Aristotelicasmallcaps-regular.ttf);
}
@font-face {
  font-family: Barcelony;
  src: url(../assets/CustomizerFont/Barcelony.ttf);
}
@font-face {
  font-family: Bilboswashcaps;
  src: url(../assets/CustomizerFont/Bilboswashcaps-regular.ttf);
}
@font-face {
  font-family: Birthstone;
  src: url(../assets/CustomizerFont/Birthstone-regular.ttf);
}
@font-face {
  font-family: Cabin;
  src: url(../assets/CustomizerFont/Cabin-regular.ttf);
}
@font-face {
  font-family: Cardo;
  src: url(../assets/CustomizerFont/Cardo-regular.ttf);
}
@font-face {
  font-family: Crimsonpro;
  src: url(../assets/CustomizerFont/Crimsonpro-variablefont-wght.ttf);
}
@font-face {
  font-family: Exo2;
  src: url(../assets/CustomizerFont/Exo2-regular.ttf);
}
@font-face {
  font-family: Fabulous;
  src: url(../assets/CustomizerFont/Fabulous.ttf);
}
@font-face {
  font-family: Freehand521;
  src: url(../assets/CustomizerFont/Freehand521-bt.ttf);
}
@font-face {
  font-family: Gafta;
  src: url(../assets/CustomizerFont/Gafta.ttf);
}
@font-face {
  font-family: Heartandsoul;
  src: url(../assets/CustomizerFont/Heartandsoul-regular.ttf);
}
@font-face {
  font-family: Hello;
  src: url(../assets/CustomizerFont/Hello.ttf);
}
@font-face {
  font-family: Hijrnotes;
  src: url(../assets/CustomizerFont/Hijrnotes-personal-use-only.ttf);
}
@font-face {
  font-family: Josephsophia;
  src: url(../assets/CustomizerFont/Josephsophia.ttf);
}
@font-face {
  font-family: Karla;
  src: url(../assets/CustomizerFont/Karla-regular.ttf);
}
@font-face {
  font-family: Kaufmann;
  src: url(../assets/CustomizerFont/Kaufmann-bt.ttf);
}
@font-face {
  font-family: Lhandw;
  src: url(../assets/CustomizerFont/Lhandw.ttf);
}
@font-face {
  font-family: Lobster;
  src: url(../assets/CustomizerFont/Lobster-regular.ttf);
}
@font-face {
  font-family: Lora;
  src: url(../assets/CustomizerFont/Lora-italic.ttf);
}
@font-face {
  font-family: Lovelyhome;
  src: url(../assets/CustomizerFont/Lovelyhome-9abz.ttf);
}
@font-face {
  font-family: Monotypecorsva;
  src: url(../assets/CustomizerFont/Monotypecorsva.ttf);
}
@font-face {
  font-family: Montserrat;
  src: url(../assets/CustomizerFont/Montserrat-extralight.ttf);
}
@font-face {
  font-family: Montserrat2;
  src: url(../assets/CustomizerFont/Montserrat-regular.ttf);
}
@font-face {
  font-family: Neuton;
  src: url(../assets/CustomizerFont/Neuton-regular.ttf);
}
@font-face {
  font-family: Opensans;
  src: url(../assets/CustomizerFont/Opensans-regular.ttf);
}
@font-face {
  font-family: Pacifico;
  src: url(../assets/CustomizerFont/Pacifico-regular.ttf);
}
@font-face {
  font-family: Poppins;
  src: url(../assets/CustomizerFont/Poppins-regular.ttf);
}
@font-face {
  font-family: Prozalibre;
  src: url(../assets/CustomizerFont/Prozalibre-regular.ttf);
}
@font-face {
  font-family: Quicksand;
  src: url(../assets/CustomizerFont/Quicksand-regular.ttf);
}
@font-face {
  font-family: Waterfall;
  src: url(../assets/CustomizerFont/Waterfall-regular.ttf);
}
.Home {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fffafa;
}
.Home-container {
  padding: 40px 40px 30px 40px;
  width: 100%;
  max-width: 1400px;
  height: 100%;
  display: flex;
}
@media (max-width: 992px) {
  .Home-container {
    padding: 20px 0 2px 0;
    flex-direction: column;
    justify-content: flex-end;
  }
}

.Login {
  width: 100%;
  height: 100vh;
  background-color: #212529;
  display: flex;
  justify-content: center;
  align-items: center;
}
.Login-container {
  background-color: #FFFFFF;
  box-shadow: 7px 7px 37px 0px rgba(0, 0, 0, 0.2509803922);
  box-shadow: -7px -7px 37px 0px rgba(0, 0, 0, 0.2509803922);
  border-radius: 8px;
  padding: 70px 40px;
  width: 40%;
}
.Login-container > div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.Login-logo {
  width: 40%;
  height: auto;
}
.Login-logo img {
  width: 100%;
  height: auto;
}
.Login-title {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
}
.Login-title h6 {
  margin: 0;
  font-size: 28px;
}
.Login-text {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 16px;
}
.Login-text p {
  margin-bottom: 0;
  font-size: 18px;
}
.Login-form {
  margin-top: 40px;
  width: 95%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.Login-form-user {
  margin-bottom: 20px;
  width: 100%;
}
.Login-form-user label {
  font-size: 13px;
  font-weight: 500;
  margin-bottom: 0.2rem;
  color: #5C5E62;
}
.Login-form-user input {
  width: 100%;
  border: 0.5px solid #393C41;
  border-radius: 4px;
  outline: 0;
}
.Login-form-user input:focus {
  border: 0.5px solid #393C41;
  outline: 0;
  box-shadow: none;
}
.Login-form-pass {
  margin-bottom: 30px;
  width: 100%;
}
.Login-form-pass label {
  font-size: 13px;
  font-weight: 500;
  margin-bottom: 0.2rem;
  color: #5C5E62;
}
.Login-form-pass input {
  width: 100%;
  border: 0.5px solid #393C41;
  border-radius: 4px;
  outline: 0;
}
.Login-form-pass input:focus {
  border: 0.5px solid #393C41;
  outline: 0;
  box-shadow: none;
}
.Login-form-submit {
  border: 1px solid #FFFFFF;
  border-radius: 4px;
  background-color: #B04953;
  color: #FFFFFF;
  width: 101%;
  height: 40px;
  font-weight: 500;
}

.AdminPanel {
  width: 100%;
  height: 100vh;
  display: flex;
}

.AdminPanelSideBar {
  width: 16%;
  height: 100%;
  background-color: #212529;
}
.AdminPanelSideBar-logo {
  height: 12%;
  width: 100%;
  border-bottom: 1px solid #393C41;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 40px 10px 20px;
}
.AdminPanelSideBar-logo img {
  width: 90%;
  height: auto;
}
@media (max-width: 1200px) {
  .AdminPanelSideBar-logo img {
    width: 100%;
  }
}
@media (max-width: 1200px) {
  .AdminPanelSideBar-logo {
    height: 10%;
  }
}
.AdminPanelSideBar-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 10px 30px 10px 15px;
}
.AdminPanelSideBar-list-item {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 10px;
  border-radius: 8px;
  cursor: pointer;
}
.AdminPanelSideBar-list-item-logo {
  width: 16%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 5px;
}
.AdminPanelSideBar-list-item-logo img {
  width: 100%;
  height: auto;
}
.AdminPanelSideBar-list-item-text {
  color: #FFFFFF;
  font-size: 13px;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (max-width: 1200px) {
  .AdminPanelSideBar-list-item-text {
    font-size: 12px;
  }
}
@media (max-width: 1200px) {
  .AdminPanelSideBar-list {
    padding: 10px 10px 10px 10px;
  }
}

.ThreeDviewer {
  width: 84%;
  height: 100%;
  background-color: #f8f9fa;
}
.ThreeDviewer-title {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 20px;
  border: 1px solid #393C41;
}
.ThreeDviewer-title h4 {
  font-size: 18px;
  font-weight: 600;
  margin: 0;
  color: #212529;
}
.ThreeDviewer-content {
  padding: 25px 20px;
  display: flex;
  width: 100%;
  gap: 20px;
}
.ThreeDviewer .ThreeDviewer-card {
  padding: 20px 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 1px solid #212529;
  width: 27%;
  cursor: pointer;
}
.ThreeDviewer .ThreeDviewer-card-image {
  width: 100%;
  height: auto;
  margin-bottom: 20px;
}
.ThreeDviewer .ThreeDviewer-card-image img {
  width: 49%;
  height: auto;
}
.ThreeDviewer .ThreeDviewer-card-title {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 5px;
  color: #000000;
}
@media (max-width: 1200px) {
  .ThreeDviewer .ThreeDviewer-card-title {
    font-size: 15px;
  }
}
.ThreeDviewer .ThreeDviewer-card-subTitle {
  font-size: 13px;
  color: #393C41;
}
@media (max-width: 1200px) {
  .ThreeDviewer .ThreeDviewer-card-subTitle {
    font-size: 10px;
  }
}

.RoseConfiguration {
  width: 84%;
  height: 100%;
  background-color: #f8f9fa;
}
.RoseConfiguration-title {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 20px;
  border: 1px solid #393C41;
}
.RoseConfiguration-title h4 {
  font-size: 18px;
  font-weight: 600;
  margin: 0;
  color: #212529;
}
.RoseConfiguration-title-backIcon {
  width: 30px;
  height: 30px;
  border: 1px solid #000000;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  cursor: pointer;
}
.RoseConfiguration-title-backIcon img {
  width: 9px;
}
.RoseConfiguration-content {
  padding: 25px 20px;
  width: 100%;
  height: 89%;
}
.RoseConfiguration-content > div {
  height: 100%;
}
.RoseConfiguration .AddType {
  width: 100%;
  height: 42%;
  overflow: hidden;
  margin-bottom: 16px;
}
.RoseConfiguration .AddType-title {
  margin-bottom: 16px;
}
.RoseConfiguration .AddType-title h5 {
  font-size: 17px;
  margin: 0;
  color: #212529;
}
.RoseConfiguration .AddType-content {
  display: flex;
  column-gap: 20px;
  row-gap: 10px;
  flex-wrap: wrap;
  width: 100%;
  height: 90%;
  overflow: auto;
}
.RoseConfiguration .AddType-card {
  height: 75%;
}
.RoseConfiguration .AddType-card > div {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
}
.RoseConfiguration .AddType-card-header {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border: 2px solid #000000;
  padding: 10px 30px;
  height: 74%;
  min-height: fit-content;
}
.RoseConfiguration .AddType-card-header-img {
  width: 65px;
}
.RoseConfiguration .AddType-card-header-img img {
  width: 100%;
  height: auto;
  object-fit: cover;
}
@media (max-width: 1200px) {
  .RoseConfiguration .AddType-card-header-img {
    width: 45px;
  }
}
.RoseConfiguration .AddType-card-header span {
  font-size: 14px;
  font-weight: 600;
}
.RoseConfiguration .AddType-card-footer {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-top: 10px;
  width: 100%;
  height: 24%;
}
.RoseConfiguration .AddType-card-footer-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}
.RoseConfiguration .AddType-card-footer-item img {
  width: 20px;
  height: auto;
  margin-bottom: 4px;
}
.RoseConfiguration .AddType-card-footer-item span {
  font-size: 9px;
  font-weight: 600;
  color: #393C41;
}
.RoseConfiguration .AddType-cardPlus {
  height: 75%;
}
.RoseConfiguration .AddType-cardPlus > div {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
}
.RoseConfiguration .AddType-cardPlus-header {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border: 2px dashed #000000;
  padding: 10px 30px;
  height: 74%;
  position: relative;
  cursor: pointer;
}
.RoseConfiguration .AddType-cardPlus-header img {
  width: 60px;
  height: auto;
}
@media (max-width: 1200px) {
  .RoseConfiguration .AddType-cardPlus-header img {
    width: 50px;
  }
}
.RoseConfiguration .AddType-cardPlus-header span {
  font-size: 14px;
  font-weight: 600;
}
.RoseConfiguration .AddType-cardPlus-header button {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0;
}
.RoseConfiguration .AddType-cardPlus-footer {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-top: 10px;
  width: 100%;
  height: 24%;
}
.RoseConfiguration .AddType-cardPlus-footer-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}
.RoseConfiguration .AddType-cardPlus-footer-item img {
  width: 20px;
  height: auto;
  margin-bottom: 4px;
}
.RoseConfiguration .AddType-cardPlus-footer-item span {
  font-size: 9px;
  font-weight: 600;
  color: #393C41;
}
.RoseConfiguration .AddRoseColors {
  width: 100%;
  height: 57%;
  overflow: hidden;
}
.RoseConfiguration .AddRoseColors-title {
  margin-bottom: 25px;
}
.RoseConfiguration .AddRoseColors-title h5 {
  font-size: 17px;
  margin: 0;
  color: #212529;
}
.RoseConfiguration .AddRoseColors-content {
  display: flex;
  column-gap: 30px;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
  overflow: auto;
}
.RoseConfiguration .AddRoseColors-card {
  height: 50%;
  width: 130px;
}
.RoseConfiguration .AddRoseColors-card > div {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
}
.RoseConfiguration .AddRoseColors-card-row1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 8px 10px;
  border-radius: 4px;
  margin-bottom: 4px;
}
.RoseConfiguration .AddRoseColors-card-row1-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}
.RoseConfiguration .AddRoseColors-card-row1-item img {
  width: 18px;
  height: auto;
  margin-bottom: 4px;
}
.RoseConfiguration .AddRoseColors-card-row1-item span {
  font-size: 9px;
  font-weight: 600;
  color: #393C41;
}
.RoseConfiguration .AddRoseColors-card-row2 {
  width: 100%;
}
.RoseConfiguration .AddRoseColors-card-row2-item {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 4px;
}
.RoseConfiguration .AddRoseColors-card-row2-item span {
  font-size: 12px;
  font-weight: 600;
}
.RoseConfiguration .AddRoseColors-cardPlus {
  height: 50%;
  cursor: pointer;
  position: relative;
}
.RoseConfiguration .AddRoseColors-cardPlus > div {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
}
.RoseConfiguration .AddRoseColors-cardPlus-header {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border: 2px dashed #000000;
  padding: 10px 25px;
  height: 74%;
  position: relative;
}
.RoseConfiguration .AddRoseColors-cardPlus-header img {
  width: 60px;
  height: auto;
}
@media (max-width: 1200px) {
  .RoseConfiguration .AddRoseColors-cardPlus-header img {
    width: 50px;
  }
}
.RoseConfiguration .AddRoseColors-cardPlus-header span {
  font-size: 14px;
  font-weight: 600;
}
.RoseConfiguration .AddRoseColors-cardPlus-header button {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0;
}
.RoseConfiguration .AddRoseColors-cardPlus-footer {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-top: 10px;
  width: 100%;
  height: 24%;
}
.RoseConfiguration .AddRoseColors-cardPlus-footer-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}
.RoseConfiguration .AddRoseColors-cardPlus-footer-item img {
  width: 20px;
  height: auto;
  margin-bottom: 4px;
}
.RoseConfiguration .AddRoseColors-cardPlus-footer-item span {
  font-size: 9px;
  font-weight: 600;
  color: #393C41;
}

.ModalAddRoseType .modal-header {
  border: 0;
}
.ModalAddRoseType .modal-footer {
  border: 0;
}
.ModalAddRoseType .NameInput {
  margin-bottom: 20px;
  width: 100%;
}
.ModalAddRoseType .NameInput label {
  font-size: 13px;
  font-weight: 500;
  margin-bottom: 0.2rem;
  color: #5C5E62;
}
.ModalAddRoseType .NameInput input {
  width: 100%;
  border: 0.5px solid #393C41;
  border-radius: 4px;
  outline: 0;
}
.ModalAddRoseType .NameInput input:focus {
  border: 0.5px solid #393C41;
  outline: 0;
  box-shadow: none;
}
.ModalAddRoseType .UploadInput {
  margin-bottom: 20px;
  width: 100%;
}
.ModalAddRoseType .UploadInput label {
  font-size: 13px;
  font-weight: 500;
  margin-bottom: 0.2rem;
  color: #5C5E62;
}
.ModalAddRoseType .UploadInput-div {
  border: 1px solid #393C41;
  border-radius: 4px;
  padding: 20px 0;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: auto;
}
.ModalAddRoseType .UploadInput-div-drag {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.ModalAddRoseType .UploadInput-div-drag img {
  width: 27px;
  height: 27px;
}
.ModalAddRoseType .UploadInput-div-drag span {
  font-size: 10px;
  font-weight: 600;
  line-height: 16.8px;
}
.ModalAddRoseType .UploadInput-div-drag-browseBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 20px;
  background-color: #FFFFFF;
  color: #5C5E62;
  font-size: 10px;
  font-weight: 600;
  line-height: 16.8px;
  text-align: center;
  border: 1px solid #5C5E62;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 5px;
}
.ModalAddRoseType .UploadInput-div-drag-browseBtn:hover {
  background-color: #B04953;
  color: #FFFFFF;
  transition: all 0.3s;
}
.ModalAddRoseType .UploadInput-div-drag-browseBtn:active {
  background-color: #B04953;
  color: #FFFFFF;
  transition: all 0.3s;
}
.ModalAddRoseType .UploadInput-div-drag input {
  display: none;
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}
.ModalAddRoseType .UploadInput-show {
  width: 250px;
  height: auto;
  position: relative;
}
.ModalAddRoseType .UploadInput-show-closeBtn {
  position: absolute;
  top: 0;
  right: 0;
  background-color: #FFFFFF;
  padding: 0 3px;
  cursor: pointer;
}
.ModalAddRoseType .UploadInput-show-closeBtn i {
  font-size: 20px;
}
.ModalAddRoseType .UploadInput-show-closeBtn i::before {
  font-weight: 900 !important;
}
.ModalAddRoseType .UploadInput-show img {
  width: 100%;
  height: auto;
}
.ModalAddRoseType .SaveBtn button {
  border: 1px solid #FFFFFF;
  background-color: #393C41;
  border-radius: 4px;
  color: #FFFFFF;
  padding: 7px 40px;
  font-size: 12px;
  font-weight: 600;
  transition: all 0.3s;
}
.ModalAddRoseType .SaveBtn button:hover {
  color: #393C41;
  background-color: #FFFFFF;
  transition: all 0.3s;
}
.ModalAddRoseType .SaveBtn button:active {
  color: #393C41;
  background-color: #FFFFFF;
}
.ModalAddRoseType .modal-backdrop {
  background: rgba(51, 51, 51, 0.6705882353);
}
.ModalAddRoseType .modal-backdrop.show {
  opacity: 0.9;
  backdrop-filter: blur(5px);
}

.ModalAddRoseColor .modal-header {
  border: 0;
}
.ModalAddRoseColor .modal-footer {
  border: 0;
}
.ModalAddRoseColor .modal-content {
  position: relative;
}
.ModalAddRoseColor .CodeOfColor {
  margin-bottom: 20px;
  width: 100%;
}
.ModalAddRoseColor .CodeOfColor label {
  font-size: 13px;
  font-weight: 500;
  margin-bottom: 0.2rem;
  color: #5C5E62;
}
.ModalAddRoseColor .CodeOfColor-input {
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 8px;
}
.ModalAddRoseColor .CodeOfColor-input input {
  width: 70%;
  border: 0.5px solid #393C41;
  border-radius: 4px;
  outline: 0;
}
.ModalAddRoseColor .CodeOfColor-input input:focus {
  border: 0.5px solid #393C41;
  outline: 0;
  box-shadow: none;
}
.ModalAddRoseColor .CodeOfColor-input button {
  width: 30%;
  font-size: 12px;
  font-weight: 500;
  padding: 0 10px;
  background-color: transparent;
  border: 1px solid #B04953;
  color: #B04953;
}
.ModalAddRoseColor .CodeOfColor-input button:hover {
  color: #FFFFFF;
  background-color: #B04953;
  transition: all 0.3s;
}
.ModalAddRoseColor .CodeOfColor-input button:active {
  color: #FFFFFF;
  background-color: #B04953;
}
.ModalAddRoseColor .Hex {
  margin-bottom: 20px;
  width: 100%;
}
.ModalAddRoseColor .Hex label {
  font-size: 13px;
  font-weight: 500;
  margin-bottom: 0.2rem;
  color: #5C5E62;
}
.ModalAddRoseColor .Hex input {
  width: 100%;
  border: 0.5px solid #393C41;
  border-radius: 4px;
  outline: 0;
}
.ModalAddRoseColor .Hex input:focus {
  border: 0.5px solid #393C41;
  outline: 0;
  box-shadow: none;
}
.ModalAddRoseColor .RGB {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
}
.ModalAddRoseColor .RGB-item {
  margin-bottom: 20px;
  width: 30%;
}
.ModalAddRoseColor .RGB-item label {
  font-size: 13px;
  font-weight: 500;
  margin-bottom: 0.2rem;
  color: #5C5E62;
}
.ModalAddRoseColor .RGB-item input {
  width: 100%;
  border: 0.5px solid #393C41;
  border-radius: 4px;
  outline: 0;
}
.ModalAddRoseColor .RGB-item input:focus {
  border: 0.5px solid #393C41;
  outline: 0;
  box-shadow: none;
}
.ModalAddRoseColor .ColorShow {
  width: 100%;
  height: 50px;
  border-radius: 4px;
}
.ModalAddRoseColor .Palet {
  position: absolute;
  right: -42%;
}
.ModalAddRoseColor .SaveBtn {
  width: 100%;
}
.ModalAddRoseColor .SaveBtn button {
  width: 100%;
  border: 1px solid #FFFFFF;
  background-color: #393C41;
  border-radius: 4px;
  color: #FFFFFF;
  padding: 10px 0;
  font-size: 12px;
  font-weight: 600;
  transition: all 0.3s;
}
.ModalAddRoseColor .SaveBtn button:hover {
  color: #393C41;
  background-color: #FFFFFF;
  transition: all 0.3s;
}
.ModalAddRoseColor .SaveBtn button:active {
  color: #393C41;
  background-color: #FFFFFF;
}
.ModalAddRoseColor .modal-backdrop {
  background: rgba(51, 51, 51, 0.6705882353);
}
.ModalAddRoseColor .modal-backdrop.show {
  opacity: 0.9;
  backdrop-filter: blur(5px);
}

.DesignConfiguration {
  width: 84%;
  height: 100%;
  background-color: #f8f9fa;
}
.DesignConfiguration-title {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 20px;
  border: 1px solid #393C41;
}
.DesignConfiguration-title h4 {
  font-size: 18px;
  font-weight: 600;
  margin: 0;
  color: #212529;
}
.DesignConfiguration-title-backIcon {
  width: 30px;
  height: 30px;
  border: 1px solid #000000;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  cursor: pointer;
}
.DesignConfiguration-title-backIcon img {
  width: 9px;
}
.DesignConfiguration-content {
  padding: 25px 20px;
  width: 100%;
  height: 89%;
}
.DesignConfiguration-content > div {
  height: 100%;
}
.DesignConfiguration .AddFont {
  width: 100%;
  height: 42%;
  overflow: hidden;
  margin-bottom: 16px;
}
.DesignConfiguration .AddFont-title {
  margin-bottom: 16px;
}
.DesignConfiguration .AddFont-title h5 {
  font-size: 17px;
  margin: 0;
  color: #212529;
}
.DesignConfiguration .AddFont-content {
  display: flex;
  column-gap: 20px;
  row-gap: 10px;
  flex-wrap: wrap;
  width: 100%;
  height: 90%;
  overflow: auto;
}
.DesignConfiguration .AddFont-content > div {
  height: 65%;
}
.DesignConfiguration .AddFont-card {
  height: 65%;
}
.DesignConfiguration .AddFont-card > div {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
}
.DesignConfiguration .AddFont-card-header {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #000000;
  padding: 10px 20px;
  height: 74%;
  min-height: fit-content;
}
.DesignConfiguration .AddFont-card-header span {
  font-size: 26px;
  font-weight: 600;
}
.DesignConfiguration .AddFont-card-footer {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-top: 10px;
  width: 100%;
  height: 24%;
}
.DesignConfiguration .AddFont-card-footer-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}
.DesignConfiguration .AddFont-card-footer-item img {
  width: 20px;
  height: auto;
  margin-bottom: 4px;
}
.DesignConfiguration .AddFont-card-footer-item span {
  font-size: 9px;
  font-weight: 600;
  color: #393C41;
}
.DesignConfiguration .AddFont-cardPlus {
  height: 65%;
}
.DesignConfiguration .AddFont-cardPlus > div {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
}
.DesignConfiguration .AddFont-cardPlus-header {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  border: 2px dashed #000000;
  padding: 0px 15px;
  height: 74%;
  min-height: fit-content;
  position: relative;
  cursor: pointer;
}
.DesignConfiguration .AddFont-cardPlus-header img {
  width: 60px;
  height: auto;
}
@media (max-width: 1200px) {
  .DesignConfiguration .AddFont-cardPlus-header img {
    width: 50px;
  }
}
.DesignConfiguration .AddFont-cardPlus-header span {
  font-size: 14px;
  font-weight: 600;
}
.DesignConfiguration .AddFont-cardPlus-header button {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0;
}
.DesignConfiguration .AddFont-cardPlus-footer {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-top: 10px;
  width: 100%;
  height: 24%;
}
.DesignConfiguration .AddFontColors {
  width: 100%;
  height: 57%;
  overflow: hidden;
}
.DesignConfiguration .AddFontColors-title {
  margin-bottom: 25px;
}
.DesignConfiguration .AddFontColors-title h5 {
  font-size: 17px;
  margin: 0;
  color: #212529;
}
.DesignConfiguration .AddFontColors-content {
  display: flex;
  column-gap: 30px;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
  overflow: auto;
}
.DesignConfiguration .AddFontColors-card {
  height: 50%;
  width: 130px;
}
.DesignConfiguration .AddFontColors-card > div {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
}
.DesignConfiguration .AddFontColors-card-row1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 8px 10px;
  border-radius: 4px;
  margin-bottom: 4px;
}
.DesignConfiguration .AddFontColors-card-row1-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}
.DesignConfiguration .AddFontColors-card-row1-item img {
  width: 18px;
  height: auto;
  margin-bottom: 4px;
}
.DesignConfiguration .AddFontColors-card-row1-item span {
  font-size: 9px;
  font-weight: 600;
  color: #393C41;
}
.DesignConfiguration .AddFontColors-card-row2 {
  width: 100%;
}
.DesignConfiguration .AddFontColors-card-row2-item {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 4px;
}
.DesignConfiguration .AddFontColors-card-row2-item span {
  font-size: 12px;
  font-weight: 600;
}
.DesignConfiguration .AddFontColors-cardPlus {
  height: 50%;
  cursor: pointer;
  position: relative;
}
.DesignConfiguration .AddFontColors-cardPlus > div {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
}
.DesignConfiguration .AddFontColors-cardPlus-header {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  border: 2px dashed #000000;
  padding: 0px 25px;
  height: 74%;
  position: relative;
}
.DesignConfiguration .AddFontColors-cardPlus-header img {
  width: 60px;
  height: auto;
}
@media (max-width: 1200px) {
  .DesignConfiguration .AddFontColors-cardPlus-header img {
    width: 50px;
  }
}
.DesignConfiguration .AddFontColors-cardPlus-header span {
  font-size: 14px;
  font-weight: 600;
}
.DesignConfiguration .AddFontColors-cardPlus-header button {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0;
}

.ModalAddFont .modal-header {
  border: 0;
}
.ModalAddFont .modal-footer {
  border: 0;
}
.ModalAddFont .NameInput {
  margin-bottom: 20px;
  width: 100%;
}
.ModalAddFont .NameInput label {
  font-size: 13px;
  font-weight: 500;
  margin-bottom: 0.2rem;
  color: #5C5E62;
}
.ModalAddFont .NameInput input {
  width: 100%;
  border: 0.5px solid #393C41;
  border-radius: 4px;
  outline: 0;
}
.ModalAddFont .NameInput input:focus {
  border: 0.5px solid #393C41;
  outline: 0;
  box-shadow: none;
}
.ModalAddFont .UploadInput {
  margin-bottom: 20px;
  width: 100%;
}
.ModalAddFont .UploadInput label {
  font-size: 13px;
  font-weight: 500;
  margin-bottom: 0.2rem;
  color: #5C5E62;
}
.ModalAddFont .UploadInput-div {
  border: 1px solid #393C41;
  border-radius: 4px;
  padding: 20px 0;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: auto;
}
.ModalAddFont .UploadInput-div-drag {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.ModalAddFont .UploadInput-div-drag img {
  width: 27px;
  height: 27px;
}
.ModalAddFont .UploadInput-div-drag span {
  font-size: 10px;
  font-weight: 600;
  line-height: 16.8px;
}
.ModalAddFont .UploadInput-div-drag-browseBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 20px;
  background-color: #FFFFFF;
  color: #5C5E62;
  font-size: 10px;
  font-weight: 600;
  line-height: 16.8px;
  text-align: center;
  border: 1px solid #5C5E62;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 5px;
}
.ModalAddFont .UploadInput-div-drag-browseBtn:hover {
  background-color: #B04953;
  color: #FFFFFF;
  transition: all 0.3s;
}
.ModalAddFont .UploadInput-div-drag-browseBtn:active {
  background-color: #B04953;
  color: #FFFFFF;
  transition: all 0.3s;
}
.ModalAddFont .UploadInput-div-drag input {
  display: none;
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}
.ModalAddFont .UploadInput-show {
  width: 150px;
  height: auto;
  position: relative;
}
.ModalAddFont .UploadInput-show-closeBtn {
  position: absolute;
  top: 0;
  right: 0;
  background-color: #FFFFFF;
  padding: 0 3px;
  cursor: pointer;
}
.ModalAddFont .UploadInput-show-closeBtn i {
  font-size: 20px;
}
.ModalAddFont .UploadInput-show-closeBtn i::before {
  font-weight: 900 !important;
}
.ModalAddFont .UploadInput-show img {
  width: 100%;
  height: auto;
}
.ModalAddFont .SaveBtn button {
  border: 1px solid #FFFFFF;
  background-color: #393C41;
  border-radius: 4px;
  color: #FFFFFF;
  padding: 7px 40px;
  font-size: 12px;
  font-weight: 600;
  transition: all 0.3s;
}
.ModalAddFont .SaveBtn button:hover {
  color: #393C41;
  background-color: #FFFFFF;
  transition: all 0.3s;
}
.ModalAddFont .SaveBtn button:active {
  color: #393C41;
  background-color: #FFFFFF;
}
.ModalAddFont .modal-backdrop {
  background: rgba(51, 51, 51, 0.6705882353);
}
.ModalAddFont .modal-backdrop.show {
  opacity: 0.9;
  backdrop-filter: blur(5px);
}

.PackagingConfiguration {
  width: 84%;
  height: 100%;
  background-color: #f8f9fa;
}
.PackagingConfiguration-title {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 20px;
  border: 1px solid #393C41;
}
.PackagingConfiguration-title h4 {
  font-size: 18px;
  font-weight: 600;
  margin: 0;
  color: #212529;
}
.PackagingConfiguration-title-backIcon {
  width: 30px;
  height: 30px;
  border: 1px solid #000000;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  cursor: pointer;
}
.PackagingConfiguration-title-backIcon img {
  width: 9px;
}
.PackagingConfiguration-content {
  padding: 25px 20px;
  width: 100%;
  height: 89%;
}
.PackagingConfiguration-content > div {
  height: 100%;
}
.PackagingConfiguration .AddBoxType {
  width: 100%;
  height: 20%;
  margin-bottom: 16px;
  display: flex;
  align-content: flex-start;
  overflow: auto;
  flex-wrap: wrap;
}
.PackagingConfiguration .AddBoxType-title {
  margin-bottom: 16px;
  height: fit-content;
  margin-right: 35px;
}
.PackagingConfiguration .AddBoxType-title h5 {
  font-size: 17px;
  margin: 0;
  color: #212529;
}
.PackagingConfiguration .AddBoxType-content {
  display: flex;
  height: auto;
  column-gap: 15px;
  row-gap: 15px;
  flex-wrap: wrap;
}
.PackagingConfiguration .AddBoxType-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #5C5E62;
  border-radius: 4px;
  padding: 7px;
  height: fit-content;
  width: 150px;
}
.PackagingConfiguration .AddBoxType-item-title {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 0;
}
.PackagingConfiguration .AddBoxType-item-icons {
  display: flex;
  align-items: center;
}
.PackagingConfiguration .AddBoxType-item-icons img {
  width: 18px;
  height: auto;
  margin-left: 15px;
  cursor: pointer;
}
.PackagingConfiguration .AddBoxType-plus {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px dashed #5C5E62;
  border-radius: 4px;
  padding: 7px;
  height: fit-content;
  width: 150px;
  cursor: pointer;
  position: relative;
}
.PackagingConfiguration .AddBoxType-plus img {
  width: 23px;
  height: auto;
}
.PackagingConfiguration .AddBoxType-plus button {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0;
}
.PackagingConfiguration .AddBox {
  width: 100%;
  height: 79%;
  overflow: hidden;
}
.PackagingConfiguration .AddBox-title {
  margin-bottom: 25px;
  width: 100%;
}
.PackagingConfiguration .AddBox-title h5 {
  font-size: 17px;
  margin: 0;
  color: #212529;
}
.PackagingConfiguration .AddBox-filter {
  width: 100%;
  display: flex;
  align-items: center;
  column-gap: 10px;
  padding-left: 10px;
  margin-bottom: 32px;
}
.PackagingConfiguration .AddBox-filter-item {
  padding: 1px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #FFFFFF;
  border: 1px solid #9C9C9C;
  border-radius: 19px;
  cursor: pointer;
}
.PackagingConfiguration .AddBox-filter-item p {
  margin: 0;
  color: #5C5E62;
  font-size: 14px;
  font-weight: 400;
}
.PackagingConfiguration .AddBox-filter .Item-active {
  background-color: rgba(223, 129, 138, 0.4392156863) !important;
  border: 1px solid #B04953 !important;
}
.PackagingConfiguration .AddBox-content {
  display: flex;
  column-gap: 10px;
  row-gap: 10px;
  flex-wrap: wrap;
  width: 100%;
  overflow: auto;
}
.PackagingConfiguration .AddBox-card {
  background-color: #FFFFFF;
  width: 140px;
  height: 200px;
  display: flex;
  flex-direction: column;
  position: relative;
}
.PackagingConfiguration .AddBox-card-image {
  width: 100%;
  height: 65%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-bottom: 1px solid #D0D1D2;
  padding: 0 3px;
}
.PackagingConfiguration .AddBox-card-image img {
  width: 100%;
  height: auto;
}
.PackagingConfiguration .AddBox-card-text {
  width: 100%;
  height: 35%;
  padding: 10px;
}
.PackagingConfiguration .AddBox-card-text-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.PackagingConfiguration .AddBox-card-text-item p {
  margin: 0;
  font-size: 11px;
  color: #5C5E62;
}
.PackagingConfiguration .AddBox-card-text-item span {
  font-size: 11px;
  font-weight: 700;
  color: #393C41;
}
.PackagingConfiguration .AddBox-card-icons {
  position: absolute;
  top: 2px;
  left: 2px;
  background-color: #EEEEEE;
  border-radius: 4px;
  padding: 3px 5px;
  display: flex;
  align-items: center;
  column-gap: 13px;
}
.PackagingConfiguration .AddBox-card-icons img {
  width: 20px;
  height: auto;
  cursor: pointer;
}
.PackagingConfiguration .AddBox-cardPlis {
  width: 140px;
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  border: 2px dashed #000000;
  cursor: pointer;
}
.PackagingConfiguration .AddBox-cardPlis img {
  width: 50px;
  height: auto;
  margin-bottom: 5px;
}
.PackagingConfiguration .AddBox-cardPlis span {
  font-size: 12px;
  font-weight: 500;
}
.PackagingConfiguration .AddBox-cardPlis button {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0;
}

.ModalAddBoxType .modal-header {
  border: 0;
}
.ModalAddBoxType .modal-footer {
  border: 0;
}
.ModalAddBoxType .NameMaterial {
  margin-bottom: 20px;
  width: 100%;
}
.ModalAddBoxType .NameMaterial label {
  font-size: 13px;
  font-weight: 500;
  margin-bottom: 0.2rem;
  color: #5C5E62;
}
.ModalAddBoxType .NameMaterial input {
  width: 100%;
  border: 0.5px solid #393C41;
  border-radius: 4px;
  outline: 0;
}
.ModalAddBoxType .NameMaterial input:focus {
  border: 0.5px solid #393C41;
  outline: 0;
  box-shadow: none;
}
.ModalAddBoxType .SaveBtn button {
  border: 1px solid #FFFFFF;
  background-color: #393C41;
  border-radius: 4px;
  color: #FFFFFF;
  padding: 7px 40px;
  font-size: 12px;
  font-weight: 600;
  transition: all 0.3s;
}
.ModalAddBoxType .SaveBtn button:hover {
  color: #393C41;
  background-color: #FFFFFF;
  transition: all 0.3s;
}
.ModalAddBoxType .SaveBtn button:active {
  color: #393C41;
  background-color: #FFFFFF;
}

.modal-backdrop {
  background: rgba(51, 51, 51, 0.6705882353);
}

.modal-backdrop.show {
  opacity: 0.9;
  backdrop-filter: blur(5px);
}

.ModalAddBox .modal-header {
  border: 0;
}
.ModalAddBox .modal-footer {
  border: 0;
}
.ModalAddBox .Body-row1 {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  column-gap: 40px;
}
.ModalAddBox .Body-row1-item {
  width: 50%;
}
.ModalAddBox .Body-row1-item label {
  font-size: 13px;
  font-weight: 500;
  margin-bottom: 0.2rem;
  color: #5C5E62;
}
.ModalAddBox .Body-row1-item > div {
  position: relative;
}
.ModalAddBox .Body-row1-item .custom-icon {
  position: absolute;
  right: 10px;
  transform: translateY(-50%);
  width: 10px; /* عرض عکس */
  height: 10px; /* ارتفاع عکس */
  background-image: url("../assets/SelectIcon.png");
  background-size: contain; /* تنظیم سایز عکس */
  background-repeat: no-repeat; /* جلوگیری از تکرار عکس */
  transition: transform 0.3s ease;
}
.ModalAddBox .Body-row1-item select {
  border: 0.5px solid #393C41;
  border-radius: 4px;
  font-size: 13px;
  font-weight: 500;
  cursor: pointer;
}
.ModalAddBox .Body-row1-item select:focus {
  outline: 0 !important;
  box-shadow: none;
}
.ModalAddBox .Body-row1-item .custom-select {
  appearance: none;
  /* حذف فلش پیش‌فرض مرورگر */
  -webkit-appearance: none;
  -moz-appearance: none;
  background: none;
  /* حذف پس‌زمینه پیش‌فرض */
  padding-right: 30px;
  /* فضا برای آیکون */
}
.ModalAddBox .Body-row1-item option {
  font-size: 13px;
  font-weight: 500;
}
.ModalAddBox .Body-row2 {
  width: 100%;
  margin-bottom: 20px;
}
.ModalAddBox .Body-row2 .Number {
  margin-bottom: 20px;
  width: 100%;
}
.ModalAddBox .Body-row2 .Number label {
  font-size: 13px;
  font-weight: 500;
  margin-bottom: 0.2rem;
  color: #5C5E62;
}
.ModalAddBox .Body-row2 .Number input {
  width: 100%;
  border: 0.5px solid #393C41;
  border-radius: 4px;
  outline: 0;
}
.ModalAddBox .Body-row2 .Number input:focus {
  border: 0.5px solid #393C41;
  outline: 0;
  box-shadow: none;
}
.ModalAddBox .UploadInput {
  margin-bottom: 20px;
  width: 100%;
}
.ModalAddBox .UploadInput label {
  font-size: 13px;
  font-weight: 500;
  margin-bottom: 0.2rem;
  color: #5C5E62;
}
.ModalAddBox .UploadInput-div {
  border: 1px solid #393C41;
  border-radius: 4px;
  padding: 20px 0;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: auto;
}
.ModalAddBox .UploadInput-div-drag {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.ModalAddBox .UploadInput-div-drag img {
  width: 27px;
  height: 27px;
}
.ModalAddBox .UploadInput-div-drag span {
  font-size: 10px;
  font-weight: 600;
  line-height: 16.8px;
}
.ModalAddBox .UploadInput-div-drag-browseBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 20px;
  background-color: #FFFFFF;
  color: #5C5E62;
  font-size: 10px;
  font-weight: 600;
  line-height: 16.8px;
  text-align: center;
  border: 1px solid #5C5E62;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 5px;
}
.ModalAddBox .UploadInput-div-drag-browseBtn:hover {
  background-color: #B04953;
  color: #FFFFFF;
  transition: all 0.3s;
}
.ModalAddBox .UploadInput-div-drag-browseBtn:active {
  background-color: #B04953;
  color: #FFFFFF;
  transition: all 0.3s;
}
.ModalAddBox .UploadInput-div-drag input {
  display: none;
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}
.ModalAddBox .UploadInput-show {
  width: 150px;
  height: auto;
  position: relative;
}
.ModalAddBox .UploadInput-show-closeBtn {
  position: absolute;
  top: 0;
  right: 0;
  background-color: #FFFFFF;
  padding: 0 3px;
  cursor: pointer;
}
.ModalAddBox .UploadInput-show-closeBtn i {
  font-size: 20px;
}
.ModalAddBox .UploadInput-show-closeBtn i::before {
  font-weight: 900 !important;
}
.ModalAddBox .UploadInput-show img {
  width: 100%;
  height: auto;
}
.ModalAddBox .SaveBtn button {
  border: 1px solid #FFFFFF;
  background-color: #393C41;
  border-radius: 4px;
  color: #FFFFFF;
  padding: 7px 40px;
  font-size: 12px;
  font-weight: 600;
  transition: all 0.3s;
}
.ModalAddBox .SaveBtn button:hover {
  color: #393C41;
  background-color: #FFFFFF;
  transition: all 0.3s;
}
.ModalAddBox .SaveBtn button:active {
  color: #393C41;
  background-color: #FFFFFF;
}
.ModalAddBox .modal-backdrop {
  background: rgba(51, 51, 51, 0.6705882353);
}
.ModalAddBox .modal-backdrop.show {
  opacity: 0.9;
  backdrop-filter: blur(5px);
}

.UserManagment {
  width: 84%;
  height: 100%;
  background-color: #f8f9fa;
}
.UserManagment-title {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 20px;
  border: 1px solid #393C41;
}
.UserManagment-title h4 {
  font-size: 18px;
  font-weight: 600;
  margin: 0;
  color: #212529;
}
.UserManagment-content {
  padding: 25px 40px;
  width: 100%;
}
.UserManagment-table {
  display: grid;
  gap: 3px;
  overflow: hidden;
}
.UserManagment-table-header {
  display: grid;
  grid-template-columns: 1fr 1fr 2fr 1fr;
  gap: 10px;
  align-items: center;
  padding: 0 13px;
  background-color: transparent;
}
.UserManagment-table-header span {
  font-size: 12px;
  font-weight: 700;
  color: #5C5E62;
}
.UserManagment-table-header .add-user {
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
}
.UserManagment-table-header .add-user-btn {
  margin-bottom: 10px;
  padding: 10px 10px;
  background-color: #333;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  transition: all 0.3s;
}
.UserManagment-table-header .add-user-btn img {
  width: 15px;
  height: auto;
  margin-right: 3px;
}
.UserManagment-table-header .add-user-btn p {
  font-size: 14px;
  color: #FFFFFF;
  margin: 0;
}
.UserManagment-table-header .add-user-btn:hover {
  background-color: #393C41;
  transition: all 0.3s;
}
.UserManagment-table-header .add-user-btn:focus {
  background-color: #393C41;
  transition: all 0.3s;
}
.UserManagment-table-body {
  overflow: auto;
  height: 75vh;
}
.UserManagment-table-body-item {
  display: grid;
  grid-template-columns: 1fr 1fr 2fr 1fr;
  gap: 10px;
  align-items: center;
  padding: 8px;
  background-color: #FFFFFF;
  border-radius: 5px;
}
.UserManagment-table-body-item span {
  font-size: 14px;
  font-weight: 700;
  color: #000000;
}
.UserManagment-table-body-item .actions {
  display: flex;
  gap: 35px;
  align-items: center;
  justify-content: center;
}
.UserManagment-table-body-item .actions-btn {
  border: none;
  background: none;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.UserManagment-table-body-item .actions-btn img {
  width: 18px;
  height: auto;
  padding-bottom: 5px;
}
.UserManagment-table-body-item .actions-btn span {
  font-size: 8px;
  font-weight: 700;
  color: #393C41;
}
.UserManagment-table-body::-webkit-scrollbar {
  width: 5px;
}
.UserManagment-table-body::-webkit-scrollbar-track {
  background: #D0D1D2;
}
.UserManagment-table-body::-webkit-scrollbar-thumb {
  background: #5C5E62;
}

.ModalAddUser .modal-header {
  border: 0;
}
.ModalAddUser .modal-footer {
  border: 0;
}
.ModalAddUser .UserName,
.ModalAddUser .Password,
.ModalAddUser .Email {
  margin-bottom: 20px;
  width: 100%;
}
.ModalAddUser .UserName label,
.ModalAddUser .Password label,
.ModalAddUser .Email label {
  font-size: 13px;
  font-weight: 500;
  margin-bottom: 0.2rem;
  color: #5C5E62;
}
.ModalAddUser .UserName input,
.ModalAddUser .Password input,
.ModalAddUser .Email input {
  width: 100%;
  border: 0.5px solid #393C41;
  border-radius: 4px;
  outline: 0;
}
.ModalAddUser .UserName input:focus,
.ModalAddUser .Password input:focus,
.ModalAddUser .Email input:focus {
  border: 0.5px solid #393C41;
  outline: 0;
  box-shadow: none;
}
.ModalAddUser .SaveBtn button {
  border: 1px solid #FFFFFF;
  background-color: #393C41;
  border-radius: 4px;
  color: #FFFFFF;
  padding: 7px 40px;
  font-size: 12px;
  font-weight: 600;
  transition: all 0.3s;
}
.ModalAddUser .SaveBtn button:hover {
  color: #393C41;
  background-color: #FFFFFF;
  transition: all 0.3s;
}
.ModalAddUser .SaveBtn button:active {
  color: #393C41;
  background-color: #FFFFFF;
}

.Loading {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}

.LoadingMain {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #c0c0c0;
  opacity: 0.5;
}
.LoadingMain > div {
  width: 50%;
  height: 100%;
}
@media (max-width: 992px) {
  .LoadingMain > div {
    width: 100%;
    height: 45%;
  }
}

.DesignPanel {
  width: 48%;
  height: 100%;
  margin: 0;
  background-color: #ffffff;
}
.DesignPanel-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 0 2px 0;
}
@media (max-width: 992px) {
  .DesignPanel-container {
    justify-content: flex-start;
  }
}
.DesignPanel-tools {
  border: 2px solid #9C9C9C;
  border-radius: 8px;
  padding: 5px 0;
  height: 89%;
  overflow: auto;
}
.DesignPanel-tools > div {
  height: 100%;
}
.DesignPanel-tools-title {
  font-size: 17px;
  height: 8%;
  font-weight: 700;
  line-height: 28.8px;
  color: #171A20;
  margin: 0 0 5px 0;
  padding: 0px 20px;
  display: flex;
  align-items: center;
}
@media (max-width: 992px) {
  .DesignPanel-tools-title {
    font-size: 20px;
    font-weight: 600;
  }
}
@media (min-width: 1500px) {
  .DesignPanel-tools-title {
    font-size: 24px;
  }
}
.DesignPanel-tools-typeRose {
  padding: 0 25px;
  height: 90%;
}
.DesignPanel-tools-typeRose-container {
  border-top: 1px solid #9C9C9C;
  padding: 15px 0;
  height: 100%;
}
.DesignPanel-tools-typeRose-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.DesignPanel-tools-typeRose-title span {
  font-size: 14px;
  font-weight: 700;
  line-height: 28px;
  color: #171A20;
}
@media (max-width: 992px) {
  .DesignPanel-tools-typeRose-title span {
    font-size: 14px;
  }
}
@media (min-width: 1500px) {
  .DesignPanel-tools-typeRose-title span {
    font-size: 18px;
    padding: 10px 0;
  }
}
.DesignPanel-tools-typeRose-content .Rose-type {
  margin-top: 16px;
  display: flex;
  align-items: center;
  gap: 20px;
}
.DesignPanel-tools-typeRose-content .Rose-type-item {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.DesignPanel-tools-typeRose-content .Rose-type-item-image {
  border-radius: 50%;
  border: 1px solid #393C41;
  background-color: #FFFFFF;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 80px;
  margin-bottom: 10px;
  cursor: pointer;
}
.DesignPanel-tools-typeRose-content .Rose-type-item-image img {
  width: 100%;
  height: auto;
}
.DesignPanel-tools-typeRose-content .Rose-type-item-image:hover {
  background-color: #D7D8D9 !important;
}
@media (max-width: 992px) {
  .DesignPanel-tools-typeRose-content .Rose-type-item-image {
    width: 60px;
    height: 60px;
  }
}
@media (min-width: 1500px) {
  .DesignPanel-tools-typeRose-content .Rose-type-item-image {
    width: 100px;
    height: 100px;
  }
}
.DesignPanel-tools-typeRose-content .Rose-type-item .ActiveRose {
  border: 2px solid #000000 !important;
  background-color: #EFBDC2 !important;
}
.DesignPanel-tools-typeRose-content .Rose-type-item-text {
  font-size: 11px;
  font-weight: 500;
  line-height: 16.8px;
  color: #000000;
}
@media (max-width: 992px) {
  .DesignPanel-tools-typeRose-content .Rose-type-item-text {
    font-size: 11px;
    font-weight: 400;
  }
}
@media (min-width: 1500px) {
  .DesignPanel-tools-typeRose-content .Rose-type-item-text {
    font-size: 14px;
  }
}
.DesignPanel-tools-colorRose {
  padding: 0 25px;
  height: 90%;
}
.DesignPanel-tools-colorRose-container {
  border-top: 1px solid #9C9C9C;
  padding: 15px 0;
  height: 100%;
}
.DesignPanel-tools-colorRose-title {
  display: flex;
  align-items: center;
}
.DesignPanel-tools-colorRose-title span {
  font-size: 14px;
  line-height: 28px;
  font-weight: 700;
  color: #171A20;
}
@media (max-width: 992px) {
  .DesignPanel-tools-colorRose-title span {
    font-size: 14px;
  }
}
@media (min-width: 1500px) {
  .DesignPanel-tools-colorRose-title span {
    font-size: 18px;
    padding: 10px 0;
  }
}
.DesignPanel-tools-colorRose-title-backIcon {
  width: 22px;
  height: 22px;
  border: 1px solid #000000;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 6px;
  cursor: pointer;
}
.DesignPanel-tools-colorRose-title-backIcon img {
  width: 7px;
}
@media (min-width: 1500px) {
  .DesignPanel-tools-colorRose-title-backIcon img {
    width: 10px;
  }
}
@media (min-width: 1500px) {
  .DesignPanel-tools-colorRose-title-backIcon {
    width: 29px;
    height: 29px;
  }
}
.DesignPanel-tools-colorRose-content {
  margin-top: 16px;
}
.DesignPanel-tools-colorRose .Rose-color {
  margin-top: 16px;
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}
.DesignPanel-tools-colorRose .Rose-color > div {
  position: relative;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 42px;
  height: 42px;
}
.DesignPanel-tools-colorRose .Rose-color > div:hover {
  background-color: #D7D8D9;
}
@media (max-width: 992px) {
  .DesignPanel-tools-colorRose .Rose-color > div {
    width: 40px;
    height: 40px;
  }
}
@media (min-width: 1500px) {
  .DesignPanel-tools-colorRose .Rose-color > div {
    width: 45px;
    height: 45px;
  }
}
.DesignPanel-tools-colorRose .Rose-color-item {
  border-radius: 50%;
  width: 35px;
  height: 35px;
}
@media (max-width: 992px) {
  .DesignPanel-tools-colorRose .Rose-color-item {
    width: 33px;
    height: 33px;
  }
}
@media (min-width: 1500px) {
  .DesignPanel-tools-colorRose .Rose-color-item {
    width: 38px;
    height: 38px;
  }
}
.DesignPanel-tools-colorRose .Rose-color-check {
  position: absolute;
  font-size: 26px;
}
.DesignPanel-tools-colorRose .Rose-color-check::before {
  font-weight: 900 !important;
}
.DesignPanel-tools-colorRose .Rose-color img {
  border-radius: 50%;
  width: 100%;
  height: 100%;
}
@media (max-width: 992px) {
  .DesignPanel-tools-colorRose .Rose-color {
    gap: 13px;
  }
}
.DesignPanel-tools::-webkit-scrollbar {
  width: 5px;
}
.DesignPanel-tools::-webkit-scrollbar-track {
  background: #D0D1D2;
}
.DesignPanel-tools::-webkit-scrollbar-thumb {
  background: #5C5E62;
}
@media (max-width: 992px) {
  .DesignPanel-tools {
    position: relative;
  }
}
.DesignPanel-price {
  margin-top: 5px;
  padding-bottom: 5px;
  height: 10%;
}
@media (max-width: 992px) {
  .DesignPanel-price {
    margin-top: 6px;
  }
}
@media (max-width: 992px) {
  .DesignPanel {
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-start;
    width: 100%;
    flex-wrap: nowrap;
    height: 60%;
  }
}

.Price {
  background-color: #FFFFFF;
}
.Price-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 25px;
  border: 2px solid #9C9C9C;
  background-color: #FFFFFF;
  border-radius: 8px;
}
@media (min-width: 1500px) {
  .Price-container {
    height: 100%;
  }
}
.Price-number p {
  color: #A7A7A7;
  font-size: 12px;
  font-weight: 700;
  line-height: 19.2px;
  text-align: left;
  margin-bottom: 2px;
}
@media (max-width: 992px) {
  .Price-number p {
    font-size: 12px;
  }
}
@media (min-width: 1500px) {
  .Price-number p {
    font-size: 18px;
    font-weight: 700;
  }
}
.Price-number span {
  color: #000000;
  font-size: 18px;
  font-weight: 700;
  line-height: 28.8px;
  text-align: left;
}
@media (max-width: 992px) {
  .Price-number span {
    font-size: 16px;
  }
}
@media (min-width: 1500px) {
  .Price-number span {
    font-size: 24px;
    font-weight: 900;
  }
}
.Price-add button {
  border: 0;
  border-radius: 4px;
  background: #434343;
  color: #FFFFFF;
  padding: 7px 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
  text-align: left;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.2509803922);
}
.Price-add button:hover {
  color: #FFFFFF;
  background: linear-gradient(85.77deg, #323232 -3.33%, #A47378 39.48%, #3B3B3B 99.81%);
  box-shadow: none;
}
.Price-add button:active {
  background: #323232;
}
@media (max-width: 992px) {
  .Price-add button {
    font-size: 12px;
  }
}
@media (min-width: 1500px) {
  .Price-add button {
    font-size: 20px;
    font-weight: 900;
    padding: 15px 40px;
  }
}
@media (max-width: 992px) {
  .Price {
    padding: 0px 10px 10px 10px;
  }
}
@media (min-width: 1500px) {
  .Price {
    height: 100%;
  }
}

.Rose-material {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
}
.Rose-material-title {
  font-size: 14px;
  font-weight: 700;
  line-height: 21.6px;
  text-align: left;
  margin-bottom: 0;
  color: #171A20;
}
@media (max-width: 992px) {
  .Rose-material-title {
    font-size: 14px;
  }
}
@media (min-width: 1500px) {
  .Rose-material-title {
    font-size: 18px;
  }
}
.Rose-material-list {
  display: flex;
  align-items: center;
  gap: 12px;
}
.Rose-material-list-item {
  width: 58px;
  height: 30px;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  cursor: pointer;
}
.Rose-material-list-item span {
  font-size: 11px;
  font-weight: 400;
  line-height: 16.8px;
  text-align: left;
  color: #000000;
}
@media (max-width: 992px) {
  .Rose-material-list-item span {
    font-size: 12px;
  }
}
@media (min-width: 1500px) {
  .Rose-material-list-item span {
    font-size: 14px;
  }
}
@media (max-width: 992px) {
  .Rose-material-list-item {
    width: 50px;
    height: 30px;
  }
}
@media (min-width: 1500px) {
  .Rose-material-list-item {
    width: 60px;
    height: 40px;
  }
}
@media (max-width: 992px) {
  .Rose-material {
    display: flex;
  }
}

.Rose-Boxes-title {
  font-size: 14px;
  font-weight: 700;
  line-height: 21.6px;
  text-align: left;
  margin-bottom: 10px;
  color: #171A20;
}
@media (max-width: 992px) {
  .Rose-Boxes-title {
    font-size: 14px;
  }
}
@media (min-width: 1500px) {
  .Rose-Boxes-title {
    font-size: 18px;
  }
}
.Rose-Boxes-list {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 7px;
}
.Rose-Boxes-list-item {
  width: 85px;
  height: 85px;
  border: 1px solid #9C9C9C;
  border-radius: 4px;
  background-color: #F8E8E9;
  cursor: pointer;
  position: relative;
}
.Rose-Boxes-list-item img {
  width: 100%;
  height: 100%;
}
@media (max-width: 992px) {
  .Rose-Boxes-list-item {
    width: 75px;
    height: 75px;
  }
}
@media (min-width: 1500px) {
  .Rose-Boxes-list-item {
    width: 119px;
    height: 119px;
  }
}
.Rose-Boxes-list::-webkit-scrollbar {
  width: 5px;
}
.Rose-Boxes-list::-webkit-scrollbar-track {
  background: #D0D1D2;
}
.Rose-Boxes-list::-webkit-scrollbar-thumb {
  background: #5C5E62;
}
@media (max-width: 992px) {
  .Rose-Boxes-list {
    gap: 16px;
  }
}
@media (max-width: 992px) {
  .Rose-Boxes {
    display: block;
  }
}

.Rose-quantity-title {
  display: block;
  font-size: 14px;
  font-weight: 700;
  line-height: 21.6px;
  text-align: left;
  margin-bottom: 8px;
  color: #171A20;
}
@media (max-width: 992px) {
  .Rose-quantity-title {
    font-size: 14px;
  }
}
@media (min-width: 1500px) {
  .Rose-quantity-title {
    font-size: 18px;
  }
}
.Rose-quantity-list {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  column-gap: 16px;
  row-gap: 5px;
}
.Rose-quantity-list-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}
.Rose-quantity-list-item-image {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  padding: 5px;
}
.Rose-quantity-list-item-image img {
  width: 100%;
  height: auto;
  border-radius: 50%;
}
@media (min-width: 1500px) {
  .Rose-quantity-list-item-image {
    width: 48px;
    height: 48px;
  }
}
.Rose-quantity-list-item-text {
  font-size: 11px;
  font-weight: 600;
  line-height: 16.8px;
  text-align: center;
  color: #000000;
}
@media (min-width: 1500px) {
  .Rose-quantity-list-item-text {
    font-size: 14px;
  }
}
@media (min-width: 1500px) {
  .Rose-quantity-list {
    column-gap: 24px;
    margin-top: 16px;
  }
}

.DesignPanel-tools-designRose {
  padding: 0 25px;
  height: 90%;
}
.DesignPanel-tools-designRose-container {
  border-top: 1px solid #9C9C9C;
  padding: 15px 0;
  height: 100%;
}
.DesignPanel-tools-designRose-title {
  display: flex;
  align-items: center;
}
.DesignPanel-tools-designRose-title span {
  font-size: 14px;
  line-height: 28px;
  font-weight: 700;
  color: #171A20;
}
@media (max-width: 992px) {
  .DesignPanel-tools-designRose-title span {
    font-size: 14px;
  }
}
@media (min-width: 1500px) {
  .DesignPanel-tools-designRose-title span {
    font-size: 18px;
    padding: 10px 0;
  }
}
.DesignPanel-tools-designRose-title-backIcon {
  width: 22px;
  height: 22px;
  border: 1px solid #000000;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 6px;
  cursor: pointer;
}
.DesignPanel-tools-designRose-title-backIcon img {
  width: 7px;
}
@media (min-width: 1500px) {
  .DesignPanel-tools-designRose-title-backIcon img {
    width: 10px;
  }
}
@media (min-width: 1500px) {
  .DesignPanel-tools-designRose-title-backIcon {
    width: 29px;
    height: 29px;
  }
}
.DesignPanel-tools-designRose-content {
  margin-top: 16px;
  height: 93%;
}
.DesignPanel-tools-designRose-content > div {
  height: 100%;
}
.DesignPanel-tools-designRose-content .Add {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 10%;
}
.DesignPanel-tools-designRose-content .Add-item {
  display: flex;
  align-items: center;
  border: 1px solid #9C9C9C;
  border-radius: 8px;
  padding: 10px;
  cursor: pointer;
}
.DesignPanel-tools-designRose-content .Add-item-icon {
  width: 40px;
  height: 40px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.DesignPanel-tools-designRose-content .Add-item-icon img {
  width: 28px;
  height: 28px;
}
@media (max-width: 1200px) {
  .DesignPanel-tools-designRose-content .Add-item-icon img {
    width: 21px;
    height: 21px;
  }
}
@media (max-width: 1200px) {
  .DesignPanel-tools-designRose-content .Add-item-icon {
    width: 26px;
    height: 26px;
  }
}
.DesignPanel-tools-designRose-content .Add-item-icon::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: url("../assets/border2.png") no-repeat left;
  background-size: contain;
}
.DesignPanel-tools-designRose-content .Add-item-icon::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: url("../assets/border1.png") no-repeat right;
  background-size: contain;
}
.DesignPanel-tools-designRose-content .Add-item-text {
  font-size: 11px;
  font-weight: 700;
  color: #393C41;
  margin-left: 12px;
  transition: background-color 0.3s;
}
@media (max-width: 1200px) {
  .DesignPanel-tools-designRose-content .Add-item-text {
    font-size: 9px;
  }
}
@media (min-width: 1500px) {
  .DesignPanel-tools-designRose-content .Add-item-text {
    font-size: 13px;
  }
}
.DesignPanel-tools-designRose-content .Add-item:hover {
  background-color: #D7D8D9;
  transition: background-color 0.3s;
}
@media (max-width: 1200px) {
  .DesignPanel-tools-designRose-content .Add-item {
    padding: 7px;
  }
}
@media (max-width: 1024px) {
  .DesignPanel-tools-designRose-content .Add-item {
    width: 100%;
  }
}
@media (max-width: 1025px) {
  .DesignPanel-tools-designRose-content .Add {
    flex-direction: column;
    justify-content: space-between;
    gap: 5px;
    align-items: flex-start;
    height: 23%;
  }
}
.DesignPanel-tools-designRose-content .WorkSpace {
  margin-top: 16px;
  width: 100%;
  height: 87%;
}
.DesignPanel-tools-designRose-content .WorkSpace > div {
  height: 100%;
}
.DesignPanel-tools-designRose-content .WorkSpace-title {
  font-size: 10px;
  color: #171A20;
}
@media (min-width: 1500px) {
  .DesignPanel-tools-designRose-content .WorkSpace-title {
    font-size: 14px;
  }
}
.DesignPanel-tools-designRose-content .WorkSpace-content {
  border: 0.5px solid #393C41;
  border-radius: 4px;
  height: 94%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.DesignPanel-tools-designRose-content .WorkSpace-content img {
  width: 100px;
  height: 100px;
}
@media (min-width: 1500px) {
  .DesignPanel-tools-designRose-content .WorkSpace-content img {
    width: 116px;
    height: 116px;
  }
}
.DesignPanel-tools-designRose-content .WorkSpace-content p {
  margin-top: 6px;
  margin-bottom: 10px;
  font-size: 13px;
  font-weight: 700;
  color: #171A20;
}
@media (min-width: 1500px) {
  .DesignPanel-tools-designRose-content .WorkSpace-content p {
    font-size: 16px;
  }
}
.DesignPanel-tools-designRose-content .WorkSpace-content div {
  font-size: 11px;
  padding: 0 30px;
  line-height: 1.1;
  color: #5C5E62;
}
@media (min-width: 1500px) {
  .DesignPanel-tools-designRose-content .WorkSpace-content div {
    font-size: 14px;
  }
}
@media (max-width: 1025px) {
  .DesignPanel-tools-designRose-content .WorkSpace {
    height: 74%;
    margin-top: 20px;
  }
}

.AddText {
  padding: 0 25px;
  height: 80%;
}
.AddText-container {
  border-top: 1px solid #9C9C9C;
  padding: 15px 0 0 0;
  height: 100%;
  overflow: auto;
}
.AddText-title {
  display: flex;
  align-items: center;
}
.AddText-title span {
  font-size: 14px;
  line-height: 28px;
  font-weight: 700;
  color: #171A20;
}
@media (max-width: 992px) {
  .AddText-title span {
    font-size: 14px;
  }
}
@media (min-width: 1500px) {
  .AddText-title span {
    font-size: 18px;
    padding: 10px 0;
  }
}
.AddText-title-backIcon {
  width: 22px;
  height: 22px;
  border: 1px solid #000000;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 6px;
  cursor: pointer;
}
.AddText-title-backIcon img {
  width: 7px;
}
@media (min-width: 1500px) {
  .AddText-title-backIcon img {
    width: 10px;
  }
}
@media (min-width: 1500px) {
  .AddText-title-backIcon {
    width: 29px;
    height: 29px;
  }
}
.AddText-content {
  margin-top: 16px;
}
.AddText-tab {
  display: inline-flex;
  align-items: center;
}
.AddText-tab-item {
  padding-top: 4px;
  padding-bottom: 4px;
  cursor: pointer;
  font-size: 11px;
  line-height: 16.8px;
}
@media (max-width: 992px) {
  .AddText-tab-item {
    font-size: 11px;
  }
}
@media (min-width: 1500px) {
  .AddText-tab-item {
    font-size: 14px;
  }
}
.AddText-tab-content {
  padding: 10px 0;
}
.AddText-tab-content .New-text {
  width: 100%;
}
.AddText-tab-content .New-text-item {
  width: 100%;
  position: relative;
  margin-top: 4px;
}
.AddText-tab-content .New-text-item-title {
  position: absolute;
  left: 15px;
  top: 20%;
  font-size: 12px;
  font-weight: 700;
  color: #5C5E62;
}
@media (min-width: 1500px) {
  .AddText-tab-content .New-text-item-title {
    font-size: 14px;
    top: 16%;
  }
}
.AddText-tab-content .New-text-item-textarea {
  width: 100%;
  height: 35px;
  border-radius: 0px 0px 4px 4px;
  border: 1px solid #D2D2D2;
  padding: 5.7px 5px 0px 60px;
  resize: none;
  border: 0.5px solid #393C41;
  border-radius: 4px;
  font-size: 14px;
}
.AddText-tab-content .New-text-item-textarea:focus {
  outline: 0;
}
.AddText-tab-content .New-text-item-textarea::placeholder {
  font-size: 12px;
  color: #9C9C9C;
  padding-top: 2.2px;
}
@media (max-width: 992px) {
  .AddText-tab-content .New-text-item-textarea {
    width: 100%;
  }
}
@media (min-width: 1500px) {
  .AddText-tab-content .New-text-item-textarea {
    padding-left: 70px;
    padding-top: 7px;
    height: 40px;
  }
}
.AddText-tab-content .New-textFont {
  margin-top: 16px;
}
.AddText-tab-content .New-textFont-title {
  font-size: 11px;
  margin-bottom: 0;
  color: #171A20;
}
@media (min-width: 1500px) {
  .AddText-tab-content .New-textFont-title {
    font-size: 12px;
  }
}
.AddText-tab-content .New-textFont-carousel {
  width: 100%;
  text-align: center;
  margin-top: 6px;
}
.AddText-tab-content .New-textFont-carousel .slick-list {
  display: flex;
  align-items: center;
  height: 27px;
}
@media (min-width: 1500px) {
  .AddText-tab-content .New-textFont-carousel .slick-list {
    height: 30px;
  }
}
.AddText-tab-content .New-textFont-carousel .slick-track {
  display: flex;
  align-items: center;
  height: 100%;
}
.AddText-tab-content .New-textFont-carousel .slick-slide {
  display: flex;
  align-items: center;
  height: 100%;
}
.AddText-tab-content .New-textFont-carousel .slick-slide > div {
  display: flex;
  align-items: center;
  height: 100%;
}
.AddText-tab-content .New-textFont-carousel-item {
  display: flex;
  align-items: center;
  height: 100%;
}
.AddText-tab-content .New-textFont-carousel-item-button {
  width: 80px;
  height: 100%;
  margin: 0 auto;
  border: 0.5px solid #000000;
  border-radius: 4px;
  padding: 0px 10px;
  background-color: white;
  cursor: pointer;
  font-size: 9px;
  line-height: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  transition: background-color 0.3s;
}
.AddText-tab-content .New-textFont-carousel-item-button:hover {
  background-color: #f0f0f0;
  transition: background-color 0.3s;
}
@media (min-width: 1500px) {
  .AddText-tab-content .New-textFont-carousel-item-button {
    font-size: 14px;
  }
}
.AddText-tab-content .New-textFont .arrow {
  width: 17px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  cursor: pointer;
  color: #999;
  border: 1px solid #B04953;
  border-radius: 50%;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.2509803922);
  background-color: #EEBCC1;
  padding: 2px;
}
.AddText-tab-content .New-textFont .arrow i {
  font-size: 12px;
}
.AddText-tab-content .New-textFont .arrow i::before {
  font-weight: 900 !important;
}
@media (min-width: 1500px) {
  .AddText-tab-content .New-textFont .arrow i {
    font-size: 14px;
  }
}
@media (min-width: 1500px) {
  .AddText-tab-content .New-textFont .arrow {
    width: 20px;
    height: 20px;
  }
}
.AddText-tab-content .New-textFont .arrow.next {
  right: 0;
}
.AddText-tab-content .New-textFont .arrow.prev {
  left: 0;
}
.AddText-tab-content .New-textColor {
  margin-top: 16px;
}
.AddText-tab-content .New-textColor-title {
  font-size: 11px;
  margin-bottom: 0;
  color: #171A20;
}
@media (min-width: 1500px) {
  .AddText-tab-content .New-textColor-title {
    font-size: 12px;
  }
}
.AddText-tab-content .New-textColor-carousel {
  width: 100%;
  text-align: center;
  margin-top: 6px;
}
.AddText-tab-content .New-textColor-carousel-item {
  position: relative;
  border-radius: 50%;
  display: flex !important;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 32px !important;
  height: 32px;
}
.AddText-tab-content .New-textColor-carousel-item:hover {
  background-color: #D7D8D9;
}
.AddText-tab-content .New-textColor-carousel-item > div {
  border-radius: 50%;
  width: 25px;
  height: 25px;
}
@media (max-width: 992px) {
  .AddText-tab-content .New-textColor-carousel-item > div {
    width: 33px;
    height: 33px;
  }
}
@media (min-width: 1500px) {
  .AddText-tab-content .New-textColor-carousel-item > div {
    width: 35px;
    height: 35px;
  }
}
.AddText-tab-content .New-textColor-carousel-item-check {
  position: absolute;
  font-size: 22px;
}
.AddText-tab-content .New-textColor-carousel-item-check::before {
  font-weight: 900 !important;
}
@media (max-width: 992px) {
  .AddText-tab-content .New-textColor-carousel-item {
    width: 40px !important;
    height: 40px;
  }
}
@media (min-width: 1500px) {
  .AddText-tab-content .New-textColor-carousel-item {
    width: 48px !important;
    height: 48px;
  }
}
.AddText-tab-content .New-textColor .arrow {
  width: 17px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  cursor: pointer;
  color: #999;
  border: 1px solid #B04953;
  border-radius: 50%;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.2509803922);
  background-color: #EEBCC1;
  padding: 2px;
}
.AddText-tab-content .New-textColor .arrow i {
  font-size: 12px;
}
.AddText-tab-content .New-textColor .arrow i::before {
  font-weight: 900 !important;
}
@media (min-width: 1500px) {
  .AddText-tab-content .New-textColor .arrow i {
    font-size: 14px;
  }
}
@media (min-width: 1500px) {
  .AddText-tab-content .New-textColor .arrow {
    width: 20px;
    height: 20px;
  }
}
.AddText-tab-content .New-textColor .arrow.next {
  right: 0;
}
.AddText-tab-content .New-textColor .arrow.prev {
  left: 0;
}
.AddText-tab-content .History-text {
  display: flex;
  flex-wrap: wrap;
}
.AddText-tab-content .History-text-item {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px 8px;
  border: 0.5px solid #000000;
  border-radius: 4px;
  margin-right: 6px;
}
.AddText-tab-content .History-text-item p {
  margin-bottom: 0;
  font-size: 13px;
}
.AddText-tab-content .Suggestions-text {
  display: flex;
  flex-wrap: wrap;
}
.AddText-tab-content .Suggestions-text-item {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px 8px;
  border: 0.5px solid #000000;
  border-radius: 4px;
  margin-right: 6px;
}
.AddText-tab-content .Suggestions-text-item p {
  margin-bottom: 0;
  font-size: 13px;
}
.AddText .Confirm {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 16px;
  height: 40px;
  background-color: #ffffff;
  border: 1px solid #B04953;
  border-radius: 4px;
  font-weight: 600;
  transition: background-color 0.3s;
}
.AddText .Confirm:hover {
  background-color: #D7D8D9;
  transition: background-color 0.3s;
}
.AddText .Confirm:active {
  background-color: #ECB6BB;
  transition: background-color 0.3s;
}
@media (min-width: 1500px) {
  .AddText .Confirm {
    height: 56px;
    font-size: 20px;
    font-weight: 700;
  }
}

.AddImage {
  padding: 0 25px;
  height: 90%;
}
.AddImage-container {
  border-top: 1px solid #9C9C9C;
  padding: 15px 0 0 0;
  height: 100%;
}
.AddImage-title {
  display: flex;
  align-items: center;
}
.AddImage-title span {
  font-size: 14px;
  line-height: 28px;
  font-weight: 700;
  color: #171A20;
}
@media (max-width: 992px) {
  .AddImage-title span {
    font-size: 14px;
  }
}
@media (min-width: 1500px) {
  .AddImage-title span {
    font-size: 18px;
    padding: 10px 0;
  }
}
.AddImage-title-backIcon {
  width: 22px;
  height: 22px;
  border: 1px solid #000000;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 6px;
  cursor: pointer;
}
.AddImage-title-backIcon img {
  width: 7px;
}
@media (min-width: 1500px) {
  .AddImage-title-backIcon img {
    width: 10px;
  }
}
@media (min-width: 1500px) {
  .AddImage-title-backIcon {
    width: 29px;
    height: 29px;
  }
}
.AddImage-content {
  margin-top: 16px;
  height: 92%;
}
.AddImage-content > div {
  height: 100%;
}
.AddImage-tab {
  display: inline-flex;
  align-items: center;
  height: 5%;
}
.AddImage-tab-item {
  padding-top: 4px;
  padding-bottom: 4px;
  cursor: pointer;
  font-size: 12px;
  line-height: 16.8px;
}
@media (max-width: 992px) {
  .AddImage-tab-item {
    font-size: 11px;
  }
}
@media (min-width: 1500px) {
  .AddImage-tab-item {
    font-size: 14px;
  }
}
.AddImage-tab-content {
  padding: 10px 0;
  height: 94%;
}
.AddImage-tab-content .New-image {
  height: 100%;
}
.AddImage-tab-content .New-image .ImageTab {
  box-shadow: 0px 0px 0px 1px #D0D1D2 inset;
  border-radius: 4px;
  padding: 5px 0;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: auto;
}
.AddImage-tab-content .New-image .ImageTab-drag {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.AddImage-tab-content .New-image .ImageTab-drag img {
  width: 47px;
  height: 47px;
}
.AddImage-tab-content .New-image .ImageTab-drag-browseBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 30px;
  background-color: #FFFFFF;
  color: #5C5E62;
  font-size: 14px;
  font-weight: 700;
  line-height: 16.8px;
  text-align: center;
  border: 1px solid #5C5E62;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 10px;
}
.AddImage-tab-content .New-image .ImageTab-drag-browseBtn:hover {
  background-color: #B04953;
  color: #FFFFFF;
  transition: all 0.3s;
}
.AddImage-tab-content .New-image .ImageTab-drag-browseBtn:active {
  background-color: #B04953;
  color: #FFFFFF;
  transition: all 0.3s;
}
@media (min-width: 1500px) {
  .AddImage-tab-content .New-image .ImageTab-drag-browseBtn {
    height: 42px;
    font-size: 20px;
    font-weight: 700;
  }
}
.AddImage-tab-content .History-image {
  box-shadow: 0px 0px 0px 1px #D0D1D2 inset;
  border-radius: 4px;
  padding: 5px 0;
  height: 350px;
  display: flex;
  flex-wrap: wrap;
  overflow: auto;
}

.ImageRules {
  border: 2px solid #9C9C9C;
  border-radius: 8px;
  padding: 5px 0;
  height: 100%;
}
.ImageRules-title {
  display: flex;
  align-items: center;
  padding: 0 20px;
  height: 8%;
}
.ImageRules-title span {
  font-size: 17px;
  font-weight: 700;
  line-height: 28.8px;
  color: #171A20;
  margin: 0;
  padding: 15px 0;
}
@media (max-width: 992px) {
  .ImageRules-title span {
    font-size: 14px;
  }
}
.ImageRules-title-backIcon {
  width: 22px;
  height: 22px;
  border: 1px solid #000000;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 6px;
  cursor: pointer;
}
.ImageRules-title-backIcon img {
  width: 7px;
}
.ImageRules-container {
  width: 100%;
  height: 83%;
}
.ImageRules-container > div {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
}
.ImageRules-container .ImageRules-row1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  height: 20%;
}
.ImageRules-container .ImageRules-row1-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.ImageRules-container .ImageRules-row1-item img {
  width: 65px;
  height: auto;
}
.ImageRules-container .ImageRules-row1-item div {
  width: 100%;
}
.ImageRules-container .ImageRules-row1-item p {
  margin-bottom: 0;
  font-size: 10px;
  font-weight: 500;
  text-align: center;
  width: 96%;
}
.ImageRules-container .ImageRules-row2 {
  margin: 8px;
  padding: 5px 15px 5px 35px;
  height: 75%;
  overflow: auto;
  margin-right: 20px;
}
.ImageRules-container .ImageRules-row2-title {
  font-size: 12px;
  font-weight: 700;
  line-height: 1.5;
  margin-bottom: 0;
}
.ImageRules-container .ImageRules-row2-text {
  padding-left: 25px;
  margin-bottom: 4px;
}
.ImageRules-container .ImageRules-row2-text li {
  font-size: 12px;
  font-weight: 400;
  line-height: 1.5;
  list-style-type: disc;
}
.ImageRules-container .ImageRules-row2-text li u {
  text-decoration: none;
  border-bottom: 1px solid #000000;
}
.ImageRules-container .ImageRules-row2::-webkit-scrollbar {
  width: 5px;
}
.ImageRules-container .ImageRules-row2::-webkit-scrollbar-track {
  background: #D0D1D2;
}
.ImageRules-container .ImageRules-row2::-webkit-scrollbar-thumb {
  background: #5C5E62;
}
.ImageRules-confirmBtn {
  width: 90%;
  height: 7%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #B04953;
  color: #ffffff;
  border-radius: 4px;
  font-size: 16px;
  font-weight: 700;
  margin-left: auto;
  margin-right: auto;
  transition: all 0.3s;
}
.ImageRules-confirmBtn input {
  display: none;
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}
.ImageRules-confirmBtn:hover {
  background-color: #ffffff;
  color: #000000;
  transition: all 0.3s;
}
.ImageRules-confirmBtn:active {
  background-color: #ffffff;
  color: #000000;
  transition: all 0.3s;
}

.ImageUpload {
  border: 2px solid #9C9C9C;
  border-radius: 8px;
  padding: 5px 0;
  height: 100%;
}
.ImageUpload-title {
  display: flex;
  align-items: center;
  padding: 0 20px;
  height: 8%;
}
.ImageUpload-title span {
  font-size: 17px;
  font-weight: 700;
  line-height: 28.8px;
  color: #171A20;
  margin: 0;
  padding: 15px 0;
}
@media (max-width: 992px) {
  .ImageUpload-title span {
    font-size: 14px;
  }
}
.ImageUpload-title-backIcon {
  width: 22px;
  height: 22px;
  border: 1px solid #000000;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 6px;
  cursor: pointer;
}
.ImageUpload-title-backIcon img {
  width: 7px;
}
.ImageUpload-container {
  width: 100%;
  padding: 0 25px;
  height: 78%;
}
.ImageUpload-container > div {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.ImageUpload-container .ImageUpload-view {
  width: 100%;
  height: 55%;
}
.ImageUpload-container .ImageUpload-view-img {
  overflow: hidden;
  position: relative;
  width: 90%;
  height: 250px;
  overflow: hidden;
  margin: 0 auto;
  background: rgba(154, 154, 154, 0.4392156863);
}
.ImageUpload-container .ImageUpload-view-img::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  backdrop-filter: blur(5px);
  /* بلور روی کل پس‌زمینه */
  -webkit-backdrop-filter: blur(5px);
  /* پشتیبانی از مرورگرهای کروم و سافاری */
  mask: radial-gradient(circle, transparent 110px, black 120px);
  /* ماسک برای دایره شفاف */
  -webkit-mask: radial-gradient(circle, transparent 110px, black 120px);
  /* پشتیبانی از مرورگرهای قدیمی */
  pointer-events: none;
  /* جلوگیری از کلیک روی این لایه */
}
.ImageUpload-container .ImageUpload-view-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  cursor: grab;
  will-change: transform;
  /* بهبود عملکرد */
}
.ImageUpload-container .ImageUpload-view-editHandler {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 16px;
}
.ImageUpload-container .ImageUpload-view-editHandler button {
  padding: 2px 6px;
  background: rgba(239, 189, 194, 0.5019607843);
  border-radius: 50%;
  border: 0;
  cursor: pointer;
}
.ImageUpload-container .ImageUpload-view-editHandler button i {
  font-size: 22px;
}
.ImageUpload-container .ImageUpload-view-editHandler button i::after {
  font-weight: 900 !important;
}
.ImageUpload-container .ImageUpload-view-editHandler button:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}
.ImageUpload-container .ImageUpload-view-editHandler .progress-bar-container {
  position: relative;
  width: 70%;
  height: 7px;
  background: #D0D1D2;
  border-radius: 4px;
  overflow: hidden;
  margin: 0 5px;
}
.ImageUpload-container .ImageUpload-view-editHandler .progress-bar {
  position: absolute;
  height: 100%;
  background: #B04953;
  transition: width 0.2s ease-in-out;
}
.ImageUpload-container .ImageUpload-edit {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  margin-top: 32px;
  height: 50%;
}
.ImageUpload-container .ImageUpload-edit-title {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 8px;
}
.ImageUpload-container .ImageUpload-edit-title p {
  margin-bottom: 0;
  font-size: 18px;
  font-weight: 700;
}
.ImageUpload-container .ImageUpload-edit-text {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 16px;
  text-align: justify;
  padding: 0 20px;
}
.ImageUpload-container .ImageUpload-edit-text p {
  margin-bottom: 0;
  font-size: 13px;
}
.ImageUpload-container .ImageUpload-edit-tryAgain {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 16px;
}
.ImageUpload-container .ImageUpload-edit-tryAgain p {
  cursor: pointer;
  margin-bottom: 0;
  font-size: 16px;
  font-weight: 700;
  border-bottom: 2px solid #393C41;
  color: #393C41;
}
.ImageUpload-container .ImageUpload-edit-tryAgain input {
  display: none;
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}
.ImageUpload-confirmBtn {
  height: 7%;
  width: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #B04953;
  color: #ffffff;
  border-radius: 4px;
  font-size: 16px;
  font-weight: 700;
  margin-top: 32px;
  margin-left: auto;
  margin-right: auto;
  transition: all 0.3s;
}
.ImageUpload-confirmBtn:hover {
  background-color: #ffffff;
  color: #000000;
  transition: all 0.3s;
}
.ImageUpload-confirmBtn:hover {
  background-color: #ffffff;
  color: #000000;
  transition: all 0.3s;
}

.AddClipart {
  padding: 0 25px;
  height: 91%;
}
.AddClipart-container {
  border-top: 1px solid #9C9C9C;
  padding: 15px 0 0 0;
  height: 100%;
  position: relative;
}
.AddClipart-title {
  display: flex;
  align-items: center;
}
.AddClipart-title span {
  font-size: 14px;
  line-height: 28px;
  font-weight: 700;
  color: #171A20;
}
@media (max-width: 992px) {
  .AddClipart-title span {
    font-size: 14px;
  }
}
@media (min-width: 1500px) {
  .AddClipart-title span {
    font-size: 18px;
    padding: 10px 0;
  }
}
.AddClipart-title-backIcon {
  width: 22px;
  height: 22px;
  border: 1px solid #000000;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 6px;
  cursor: pointer;
}
.AddClipart-title-backIcon img {
  width: 7px;
}
@media (min-width: 1500px) {
  .AddClipart-title-backIcon img {
    width: 10px;
  }
}
@media (min-width: 1500px) {
  .AddClipart-title-backIcon {
    width: 29px;
    height: 29px;
  }
}
.AddClipart-content {
  margin-top: 16px;
  height: 80%;
}
.AddClipart-content > div {
  height: 100%;
}
.AddClipart-tab {
  display: inline-flex;
  align-items: center;
  height: 5%;
}
.AddClipart-tab-item {
  padding-top: 4px;
  padding-bottom: 4px;
  cursor: pointer;
  font-size: 12px;
  line-height: 16.8px;
}
@media (max-width: 992px) {
  .AddClipart-tab-item {
    font-size: 11px;
  }
}
@media (min-width: 1500px) {
  .AddClipart-tab-item {
    font-size: 14px;
  }
}
.AddClipart-tab-content {
  padding: 10px 0;
  height: 94%;
}
.AddClipart-tab-content .New-Clipart {
  width: 100%;
  box-shadow: 0px 0px 0px 1px #D0D1D2 inset;
  border-radius: 4px;
  padding: 5px 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  overflow: auto;
}
.AddClipart-tab-content .New-Clipart-category {
  display: flex;
  align-items: center;
  margin-top: 16px;
  margin-left: 16px;
}
.AddClipart-tab-content .New-Clipart-category-title {
  font-size: 12px;
  font-weight: 700;
  margin-right: 10px;
}
@media (min-width: 1500px) {
  .AddClipart-tab-content .New-Clipart-category-title {
    font-size: 14px;
  }
}
.AddClipart-tab-content .New-Clipart-category-list {
  width: 200px;
  height: 35px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 13px;
  font-weight: 500;
}
.AddClipart-tab-content .New-Clipart-category-list:focus {
  outline: 0;
}
@media (min-width: 1500px) {
  .AddClipart-tab-content .New-Clipart-category-list {
    font-size: 14px;
  }
}
.AddClipart-tab-content .History-Clipart {
  box-shadow: 0px 0px 0px 1px #D0D1D2 inset;
  border-radius: 4px;
  padding: 5px 0;
  min-height: 250px;
  height: auto;
  display: flex;
  flex-wrap: wrap;
  overflow: auto;
}
.AddClipart .confirmBtn {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 16px;
  height: 40px;
  background-color: #ffffff;
  border: 1px solid #B04953;
  border-radius: 4px;
  font-weight: 600;
  transition: background-color 0.3s;
}
.AddClipart .confirmBtn:hover {
  background-color: #D7D8D9;
  transition: background-color 0.3s;
}
.AddClipart .confirmBtn:active {
  background-color: #ECB6BB;
  transition: background-color 0.3s;
}
@media (min-width: 1500px) {
  .AddClipart .confirmBtn {
    height: 56px;
    font-size: 20px;
    font-weight: 700;
  }
}

.DesignPanel-tools-packaging {
  padding: 0 25px;
  height: 90%;
}
.DesignPanel-tools-packaging-container {
  border-top: 1px solid #9C9C9C;
  padding: 15px 0;
  height: 100%;
}
.DesignPanel-tools-packaging-title {
  display: flex;
  align-items: center;
}
.DesignPanel-tools-packaging-title span {
  font-size: 14px;
  line-height: 28px;
  font-weight: 700;
  color: #171A20;
}
@media (max-width: 992px) {
  .DesignPanel-tools-packaging-title span {
    font-size: 14px;
  }
}
@media (min-width: 1500px) {
  .DesignPanel-tools-packaging-title span {
    font-size: 18px;
    padding: 10px 0;
  }
}
.DesignPanel-tools-packaging-title-backIcon {
  width: 22px;
  height: 22px;
  border: 1px solid #000000;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 6px;
  cursor: pointer;
}
.DesignPanel-tools-packaging-title-backIcon img {
  width: 7px;
}
@media (min-width: 1500px) {
  .DesignPanel-tools-packaging-title-backIcon img {
    width: 10px;
  }
}
@media (min-width: 1500px) {
  .DesignPanel-tools-packaging-title-backIcon {
    width: 29px;
    height: 29px;
  }
}
.DesignPanel-tools-packaging-content {
  margin-top: 16px;
}

.DesignPanelToggler {
  position: relative;
  display: flex;
  align-items: center;
  height: 100%;
  transform: scale(1);
}
@media (max-width: 992px) {
  .DesignPanelToggler {
    display: block;
  }
}
@media (min-width: 1200px) {
  .DesignPanelToggler {
    transform: scale(1.2);
    justify-content: center;
  }
}
@media (min-width: 1400px) {
  .DesignPanelToggler {
    transform: scale(1.4);
    justify-content: center;
  }
}

.PanelToggler {
  bottom: 70px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding-left: 10px;
  height: 100%;
  margin-bottom: 50px;
}
.PanelToggler-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.PanelToggler-item-pointer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 3px;
  width: 14px;
  height: 14px;
}
.PanelToggler-item-pointer img {
  width: 100%;
  height: auto;
}
@media (max-width: 992px) {
  .PanelToggler-item-pointer {
    display: none;
  }
}
.PanelToggler-item-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  padding: 10px;
  width: 42px;
  height: 44px;
  cursor: pointer;
}
.PanelToggler-item-icon:hover {
  background-color: rgba(57, 60, 65, 0.2) !important;
}
@media (max-width: 992px) {
  .PanelToggler-item-icon {
    width: 40px;
    height: 40px;
  }
}
.PanelToggler-item-text {
  font-size: 10px;
  line-height: 14px;
  text-align: center;
  color: #000000;
  margin-left: 3px;
  white-space: nowrap;
}
@media (max-width: 992px) {
  .PanelToggler-item-text {
    font-size: 10px;
  }
}
@media (max-width: 992px) {
  .PanelToggler-item {
    flex-direction: column;
    z-index: 100;
  }
}
.PanelToggler-divider {
  position: relative;
  width: 100%;
}
.PanelToggler-divider div {
  position: relative;
  width: 3px;
  height: 8vh;
  left: 35px;
}
@media (max-width: 992px) {
  .PanelToggler-divider div {
    width: 111px;
    height: 2px;
    left: auto;
  }
}
@media (max-width: 576px) {
  .PanelToggler-divider div {
    width: 61px;
  }
}
@media (max-width: 992px) {
  .PanelToggler-divider {
    display: flex;
    justify-content: center;
    align-items: center;
    width: auto;
    height: 57%;
    margin-left: -15px;
    margin-right: -15px;
    top: 20px;
  }
}
.PanelToggler .PastI {
  border: 1px solid #000000;
  background-color: #9C9C9C;
}
.PanelToggler .ActiveI {
  border: 2px solid #000000;
  background-color: #393C41;
}
.PanelToggler .FutureI {
  border: 1px solid #393C41;
  background-color: #ffffff;
}
.PanelToggler .PastD {
  background-color: #393C41;
}
.PanelToggler .FutureD {
  background-color: #9C9C9C;
}
@media (max-width: 1400px) {
  .PanelToggler {
    bottom: 90px;
  }
}
@media (max-width: 992px) {
  .PanelToggler {
    position: relative;
    flex-direction: row;
    padding: 0;
    margin-bottom: 20px;
    height: auto;
    bottom: 0;
  }
}

.ShowPart {
  width: 52%;
  height: 100%;
}
@media (max-width: 992px) {
  .ShowPart {
    width: 100%;
    height: 40%;
    padding: 0 20px;
  }
}

.CanvasPart {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.CanvasPart .Logo {
  position: absolute;
  top: 0;
  left: 50px;
}
.CanvasPart .Logo img {
  width: 132px;
  height: auto;
}
@media (max-width: 992px) {
  .CanvasPart .Logo img {
    width: 96px;
  }
}
@media (max-width: 992px) {
  .CanvasPart .Logo {
    left: 0;
  }
}
.CanvasPart .Canvas {
  width: 90%;
  height: 90%;
  position: relative;
}
.CanvasPart .Canvas img {
  width: 100%;
  height: 100%;
}
@media (max-width: 992px) {
  .CanvasPart .Canvas {
    width: 85%;
    height: 75%;
    padding-bottom: 30px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
  }
}
.CanvasPart-controler {
  position: absolute;
  bottom: 0;
  left: 42%;
  display: flex;
  align-items: center;
  gap: 10px;
}
.CanvasPart-controler-item {
  border: 1px solid #5C5E62;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
}
.CanvasPart-controler-item:hover {
  border: 1px solid #5C5E62;
  background-color: #D7D8D9;
}
.CanvasPart-controler-item:focus {
  background-color: #393C41;
}
.CanvasPart-controler-item:hover .CanvasPart-controler-item-i {
  color: #000000 !important;
}
.CanvasPart-controler-item:focus .CanvasPart-controler-item-i {
  color: #FFFFFF !important;
}
.CanvasPart-controler-item i {
  font-size: 19px;
  color: #5C5E62;
}
.CanvasPart-controler-item i::before {
  font-weight: 900 !important;
}
@media (max-width: 992px) {
  .CanvasPart-controler-item {
    width: 22px;
    height: 22px;
  }
}
@media (max-width: 992px) {
  .CanvasPart-controler {
    right: 0px;
    top: 40%;
    flex-direction: column;
    left: auto;
  }
}